import React, { useState, useEffect } from "react";
import axios from "axios";
import Compra from "./Compra";

function ListaCompras() {
  const [ id, setId ] = useState("");
  const [ compras, setCompras ] = useState([]);

const getContact = async (ip)=> {

  const respuesta = await axios.request("http://localhost:3400/hubspot/negociosasociados?ip="+ip).then(res =>{
    return res.data
  })
  .catch(e => {
    return null
  })

  return respuesta
}

  useEffect(() => {
    const response = async () => {
      try {
        const res = await axios.request("https://api.db-ip.com/v2/free/self");
        setId(res.data.ipAddress);

        const dealsList = await getContact(res.data.ipAddress)

        console.log("dealsList:")

        dealsList.map(compra => console.log(compra))

        setCompras(dealsList) 

      } catch (e) {
        console.log(e);
      }
    };

    response()
  }, []);


  return(
   <div className="lista-compras">
    {
      compras.length != 0?
      compras.map(compra => {
        return <Compra props={compra.properties}></Compra>
      })
    :
    <div className="no-compras">No hemos encontrado compras</div> 
    }
  </div>
  )
}

export default ListaCompras;
