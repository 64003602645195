import { React, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCartShopping,
  faUser,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from '../contextos/CartContext';

function Header() {

  const { cart } = useContext(CartContext);

  const [menuActive, setMenuActive] = useState(false);

  const handleTriggerClick = () => {
    setMenuActive(!menuActive);
  };

  const handleMenuClick = () => {
    const mainMenu = document.getElementById("main_menu");
    mainMenu.classList.toggle("active");
  };

  return (
    <header className="header">
      <div className="header_logo">
        <a href="/">
          <img src="./logo-thunder.webp" alt="Thunder Logo" />
        </a>
      </div>

      <span id="hs_cos_wrapper_my_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" data-hs-cos-general-type="widget" data-hs-cos-type="menu">
        <div id="hs_menu_wrapper_my_menu" className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="Default" data-menu-id="115201409801" aria-label="Navigation Menu">
          <ul role="menu" className="active-branch">
            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com" role="menuitem">Inicio</a></li>
            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/nosotros" role="menuitem">Nosotros</a></li>
            <li className="hs-menu-item hs-menu-depth-1 triger_menu" role="none" onClick={handleMenuClick}><a href="javascript:;" role="menuitem">Motos</a></li>
            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/tiendas" role="menuitem">Agencias</a></li>
            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://blog.thundermotos.com" role="menuitem">Blog</a></li>
            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/accesorios" role="menuitem">Accesorios</a></li>
            <li className="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.thundermotos.com/contacto" role="menuitem">Contacto</a></li>
          </ul>
        </div>
      </span>

      <div id="hs_cos_wrapper_main_menu" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module">
        <section className={`main_menu horizontal_flex ${menuActive ? "active" : ""}`} id="main_menu">
          <button className="btn" id="back_btn" type="button" title="volver">
            <span className="back_icon_btn"></span>
          </button>
          <div className="main_menu_column active" data-index="1">
            <h4 className="column_title">
              Scooter
            </h4>
            <div className="models_column" data-index="1">
              <div className="model fadeUp" id="VENOM">
                <h4 className="model_title">
                  <a className="model_url" href="https://landing.thundermotos.com/thunder-venom?hsLang=es-ec" title="VENOM">VENOM</a>
                </h4>
                <span className="model_price">$1,699.00</span>
                <a className="model_url" href="https://landing.thundermotos.com/thunder-venom?hsLang=es-ec" title="VENOM">
                  <img className="model_img" src="https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=180&amp;height=180&amp;name=VENOM%20NAD-2.png" alt="VENOM" width="180" height="180" loading="lazy" srcset="https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=90&amp;height=90&amp;name=VENOM%20NAD-2.png 90w, https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=180&amp;height=180&amp;name=VENOM%20NAD-2.png 180w, https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=270&amp;height=270&amp;name=VENOM%20NAD-2.png 270w, https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=360&amp;height=360&amp;name=VENOM%20NAD-2.png 360w, https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=450&amp;height=450&amp;name=VENOM%20NAD-2.png 450w, https://www.thundermotos.com/hs-fs/hubfs/VENOM%20NAD-2.png?width=540&amp;height=540&amp;name=VENOM%20NAD-2.png 540w" sizes="(max-width: 180px) 100vw, 180px" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        id="burger-menu"
        className="burger-menu"
        onClick={handleTriggerClick}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <div className="header-iconos">
        <div>
          <a href="/miscompras">Mis Compras</a>
        </div>
        <div>
          <FontAwesomeIcon icon={faBell} />
        </div>
        <div>
          {cart ? <div>{Object.keys(cart).length}</div> : <></>}
          <a href="/carrito">
            <FontAwesomeIcon icon={faCartShopping} />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
