import React from "react";

const Producto = (props) => {
  return (
    <div className="producto">
      <div className="cont-info">
        <div
          className="image"
          style={{ backgroundImage: "url(" + props.img + ")" }}
        >
          {window.innerWidth > 900?
           <div className="cont-buttons">
            <a>VER MÁS DETALLES</a>
            <a onClick={props.handleClick}>COMPRAR AHORA</a>
          </div>
          :<></>}
        </div>
        <div className="texto">
          <p className="tipo" value={props.tipo}>
            {props.tipo}
          </p>
          <div>
            <h3 className="nombre">{props.nombre}</h3>
            <p className="precio">
              $ <span>{props.precio}</span>
            </p>
            {props.cant ? (
              <>
                {" "}
                <p className="cantidad"> cant: {props.cant}</p>{" "}
                <p className="total"> total: {props.precio * props.cant}</p>{" "}
              </>
            ) : null}
          </div>
          {window.innerWidth < 900?
           <div className="cont-buttons">
            <a>VER MÁS DETALLES</a>
            <a onClick={props.handleClick}>COMPRAR AHORA</a>
          </div>
          :<></>}
        </div>
      </div>
    </div>
  );
};

export default Producto;
