import React from 'react'
import InfoPago from '../components/InfoPago'
import FormularioCompra from '../components/FormularioCompra'
import Carrito from '../components/Carrito'

function CarritoPage() {
  return (
    <div className="cont-body">Carrito

      <Carrito/>


      <InfoPago/>

      <FormularioCompra/>
    </div>

    
  )
}

export default CarritoPage