import React, { useState, useEffect, useRef } from "react";
import Galeria from "../components/Galeria";
import CarrouselLogo from "../components/CarrouselLogo";

function GaleriaProductos() {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    //https://thunder.ployall.com
    try {
      fetch("https://thunder.ployall.com/server/hubspot/productos", {
        method: "GET",
        headers: new Headers({
          Authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response", data);
          setProductos(data.results);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="cont-body">
      <Galeria productos={productos} />
      <CarrouselLogo />
    </div>
  );
}

export default GaleriaProductos;
