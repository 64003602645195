import React from 'react'

function PopUpPago({url}) {
  return (
    <div className='cont-pago-popup'>     
        <div>
            <div className='button-pago'>
                <a href={url} target="_blank">link pago</a>
            </div>
        </div>
    </div>
  )
}

export default PopUpPago