import { React, useEffect, useState, useRef } from "react";
import Producto from "./Producto";

import { useNavigate } from "react-router-dom";

function Galeria({ productos }) {

  const navigate = useNavigate();

  function handleClick(id) {
    navigate("/detalle?"+ "id="+id);
  }

  let tipo = "deportiva";

  return (
      <div className="galeria">
        {productos.map((producto, i) => {


switch (i) {
  case 2:
    tipo = "caballito"
    break;
  case 4:
    tipo = "cafe racer"
    break;
}

          return (
            <div
              key={"producto" + producto.properties.hs_sku}
              //handleProductData("./motoTHUNDERbullet.webp", producto.properties.name, producto.properties.price)}
            className="item_product">
              <Producto
                img={"./motoTHUNDERbullet.webp"}
                nombre={producto.properties.name}
                precio={producto.properties.price}
                tipo= {tipo}
                handleClick = {()=>handleClick(producto.properties.hs_sku)}
              />
            </div>
          );
        })}
      </div>
  );
}

export default Galeria;
