import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { CartContext } from "../contextos/CartContext"

function DetalleProducto() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const { addToCart } = useContext(CartContext);

  const [producto, setProducto] = useState(null);
  //https://thunder.ployall.com/server/hubspot/productos
  useEffect(() => {
    console.log(id)
    fetch("https://thunder.ployall.com/server/ecommerce/products?id=" + id, {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + process.env.REACT_APP_HUBSPOT_API_KEY,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setProducto(data);
      }).catch(e => console.log("error set producto: " + id, e))
  }, []);


  return (
    producto ?
      <div className="cont-detalle">
        <div>
          <img src={producto.properties.hs_images} />
        </div>

        <div>
          <p className="nombre">{producto.properties.name}</p>
          <p className="precio">{producto.properties.description}</p>
          <p className="precio">$ {producto.properties.price}</p>
          <p className="precio">$ {producto.properties.precio_tc}</p>
          <p className="precio">$ {producto.properties.categoria}</p>
          <ul>
            <li>{producto.properties.hs_sku}</li>
            <li>{producto.properties.hs_sku}</li>
            <li>{producto.properties.hs_sku}</li>
            <li>{producto.properties.hs_sku}</li>
          </ul>
          <button
            onClick={() => {

              addToCart(
                {
                  id: producto.id,
                  hs_object_id: producto.properties.hs_object_id,
                  hs_sku: producto.properties.hs_sku,
                  description: producto.properties.description,
                  name: producto.properties.name,
                  price: producto.properties.price,
                  cant: 1,
                }
              )
            }
            }
          >
            Agregar al Carrito
          </button>
        </div>
      </div>
      : <></>
  )

}

export default DetalleProducto;
