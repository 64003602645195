import React, { useState, useContext } from "react";
import { CartContext } from '../contextos/CartContext';

const ProductoCarrito = ({producto}) => {

  const { cart, removeFromCart, clearCart, removeOneArticleFromCart, addOneArticleFromCart } = useContext(CartContext);

  return (
    <div className="productoCarrito">
      <div className="cont-info">
        <div>
          <img className="image" src={"./motoTHUNDERbullet.webp"} />
        </div>
        <div className="texto">
          <h3 className="nombre">{producto.nombre}</h3>
          <span class="separador"></span>
          <p className="cantidad">
            <span>{producto.cant}</span> X
          </p>
          <p className="precio">
            $ <span>{producto.price}</span>
          </p>
          <div className="cant-buttons">
          <div onClick={() => removeOneArticleFromCart(producto.id)}>-</div>
          <div onClick={() => addOneArticleFromCart(producto.id)}>+</div>
          </div>
          <p className="cerrar" onClick={() => removeFromCart(producto.id)}>
            X
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductoCarrito;
