import { React, useState, useContext } from "react";
import axios from "axios";
import PopUpPago from "./PopUpPago";
import { CartContext } from '../contextos/CartContext';

function FormularioCompra() {

  const { cart, removeFromCart, clearCart, removeOneArticleFromCart, addOneArticleFromCart } = useContext(CartContext);

  const [url, setUrl] = useState(null)
  const [requestId, setRequestId] = useState(null)
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    document: "",
    email: "",
    mobile: "",
    typedocument: "CI",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    console.log(e.target.name, e.target.value);
  };

  function validateNombre(value, tipo) {
    const regexNombre = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/;

    console.log(value, tipo);

    if (!formData.typedocument == "RUC") {
      if (regexNombre.test(value)) {
        return null;
      } else {
        return "El " + tipo + " no es valido";
      }
    } else {
      return null;
    }
  }

  function validarCorreo(email) {
    var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email === "") {
      return "Por favor, ingrese su correo electrónico.";
    } else if (!re.test(email)) {
      return "El correo electrónico ingresado no es válido.";
    } else {
      return null;
    }
  }

  const validateCedula = (value, type) => {
    const cedulaLength = value.length;
    if (type === "CI") {
      if (cedulaLength !== 10) {
        return "Cedula debe tener 10 digitos para CI";
      }
    } else if (type === "RUC") {
      if (cedulaLength !== 13) {
        return "Cedula debe tener 13 digitos para Ruc";
      }
    } else if (type === "PPN") {
      if (cedulaLength < 4 || cedulaLength > 16) {
        return "Cedula debe tener entre 4 y 16 digitos para Pasaporte";
      }
    } else {
      return "Tipo de cedula invalida";
    }
  };

  function validarNumeroEcuador(numero) {
    // Expresión regular para validar el número de teléfono de Ecuador
    const patronNumeroEcuador = /^09\d{8}$/; //0955572700

    // Verificar si el número coincide con el patrón
    if (patronNumeroEcuador.test(numero)) {
      return null;
    } else {
      return "No es un número válido de Ecuador"; // No es un número válido de Ecuador
    }
  }

  function validateTerminos(value){
    if(value) return null
    else return "No se aceptaron los terminos y condiciones"
  }

  /*
Cédula: 10 dígitos
Ruc: 13 dígitos
Pasaporte: De 4 a 16 caracteres
*/

  //validar numero de telefono

  /*
Los nombres y apelidos deben ser enviados por separado “name” y “surname” 
y no deben tener números ni caracteres especiales, deben tener únicamente letras (Incluye la ñ, tílde y espacio). 
*/

  /*
    ---------------------------------------------------------------------------------------------------------------------------------------
  ---------------------------------------------------------------------------------------------------------------------------------------
En caso de que el documento de identidad sea RUC:
Se debe enviar solo bajo nombre, la razón social o nombre comercial y en este caso se permite números.
  ---------------------------------------------------------------------------------------------------------------------------------------
    ---------------------------------------------------------------------------------------------------------------------------------------
*/

  // validar email

  //Se valida que la referencia no se repita en ningún caso, se valida vs transacciones pendientes, aprobadas y rechazadas en la base de datos.

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = [];

    if (validateNombre(formData.name, "nombre"))
      errors.push(validateNombre(formData.name, "nombre"));
    if (validateNombre(formData.surname, "apellido"))
      errors.push(validateNombre(formData.surname, "apellido"));
    if (validateCedula(formData.document, formData.typedocument))
      errors.push(validateCedula(formData.document, formData.typedocument));
    if (validarNumeroEcuador(formData.mobile))
      errors.push(validarNumeroEcuador(formData.mobile));
    if (validarCorreo(formData.email))
      errors.push(validarCorreo(formData.email));
    if(validateTerminos(formData.comunicaciones))
      errors.push(validateTerminos(formData.comunicaciones))

      if(JSON.parse(localStorage.getItem("carrito")).length < 1)
      errors.push("Carrito Vacio");

// CHECKEAR QUE NO HAYA ERRORES

    if (errors.length > 0) {
      console.log("error", errors);
    } else {

      await axios.request('https://api.db-ip.com/v2/free/self').then(response =>{

      let data = JSON.stringify({
        buyer: {
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          document: formData.document,
          documentType: formData.typedocument,
          mobile: parseInt(formData.mobile),
        },
        ipAddress: response.data.ipAddress,
        productos: localStorage.getItem("carrito"),
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "http://localhost:3400/placetopay/procesarPago", //"https://thunder.ployall.com/server/placetopay/procesarPago"
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer pat-na1-fc43f8c1-67d1-4b9d-bf25-0e33c4d64669",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          setRequestId(response.data.requestId)
          setUrl(response.data.processUrl)

          clearCart();
        })
        .catch((error) => {
          console.log(error);
        });
        
      
      })
      .catch(error => console.log(e))
    }
  };

  return (
    <>
    
    {url? 
      <PopUpPago url={url} />
      :
      <div className="cont-form">
      <p>FormularioCompra</p>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="surname">Surname:</label>
          <input
            type="text"
            id="surname"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="type-document">Type Document:</label>
          <select id="typedocument" name="typedocument" onChange={handleChange}>
            <option>CI</option>
            <option>RUC</option>
            <option>PPN</option>
          </select>
        </fieldset>
        <fieldset>
          <label htmlFor="document">Document:</label>
          <input
            type="text"
            id="document"
            name="document"
            value={formData.document}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label>Ciudad:</label>
          <select id="ciudad" name="ciudad" onChange={handleChange}>
            <option>Quito</option>
            <option>Guayaquil</option>
            <option>Manta</option>
          </select>
        </fieldset>
        <fieldset>
          <label>Envio:</label>
          <select id="envio" name="envio" onChange={handleChange}>
            <option>Retiro por local</option>
            <option>Otro</option>
          </select>
        </fieldset>
        <fieldset>
          <div>
            <div>
              <input type="checkbox" name="suscribirme" onChange={handleChange}></input>
              <label>Si, deseo suscribirme al blog y novedades.</label>
            </div>
            <div>
              <p>
                THUNDER GO! se compromete a proteger y respetar tu privacidad, y
                solo usaremos tu información personal para administrar tu cuenta
                y proporcionar los productos y servicios que nos solicitaste. De
                vez en cuando, nos gustaría ponernos en contacto contigo acerca
                de nuestros productos y servicios, así como sobre otros
                contenidos que puedan interesarte. Si aceptas que nos
                comuniquemos contigo para este fin, marca la casilla a
                continuación para indicar cómo deseas que nos comuniquemos:
              </p>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div>
            <div>
              <input type="checkbox" name="comunicaciones" onChange={handleChange}></input>
              <label>
                Acepto recibir otras comunicaciones de THUNDER GO!.*
              </label>
            </div>
            <div>
              <p>
                Puedes darte de baja de estas comunicaciones en cualquier
                momento. Para obtener más información sobre cómo darte de baja,
                nuestras prácticas de privacidad y cómo nos comprometemos a
                proteger y respetar tu privacidad, consulta nuestra Política de
                privacidad. Al hacer clic en Enviar, aceptas que THUNDER GO!
                almacene y procese la información personal suministrada arriba
                para proporcionarte el contenido solicitado.
              </p>
            </div>
          </div>
        </fieldset>
        <button type="submit">Submit</button>
      </form>
    </div>
  }
    </>
  );
}

export default FormularioCompra;
