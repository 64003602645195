import "./App.css";
import Home from "./pages/Home";
import {CartProvider} from "./contextos/CartContext"

import { React } from "react";

function App() {
  return (
    <CartProvider>
      <div className="App">
       <Home/>
      </div>
    </CartProvider>
  );
}

export default App;
