import React, { useState, useEffect, useContext } from "react";
import ProductoCarrito from "./ProductoCarrito";
import { CartContext } from "../contextos/CartContext";

function Carrito() {
  const { cart, addToCart, removeFromCart, clearCart } =
    useContext(CartContext);

  let total = 0;

  return (
    <div className="cont-carrito">
      {cart ? (
        Object.keys(cart).map((key) => {
          total += cart[key].cant * cart[key].price
          return (
            <div className="productos-select" key={"producto" + cart[key].id}>
              <ProductoCarrito producto={cart[key]} />
            </div>
          );
        })
      ) : (
        <div>No hay compras pendientes</div>
      )}
    </div>
  );
}

export default Carrito;
