import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "../components/header";
import GaleriaProductos from "./GaleriaProductos";
import DetallesProducto from "./DetallesProducto";
import CarritoPage from "./Carrito";
import Error from "./Error";
import Footer from "../components/Footer";
import MisCompras from "./MisCompras";

function Home() {

  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route index element={<GaleriaProductos />} />
          <Route path="detalle" element={<DetallesProducto />} />
          <Route path="carrito" element={<CarritoPage />} />
          <Route path="miscompras" element={<MisCompras />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <Footer/>
    </>
  );
}

export default Home;
