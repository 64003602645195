// CartContext.js
import React, { createContext, useState, useEffect } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('carrito')));

  useEffect(() => {
    // Obtener el carrito del almacenamiento local al cargar el componente
    const savedCart = localStorage.getItem('carrito');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    // Guardar el carrito en el almacenamiento local cada vez que cambie
    localStorage.setItem('carrito', JSON.stringify(cart));
  }, [cart]);

  // Funciones para agregar, eliminar y vaciar el carrito
  const addToCart = (item) => {

    const cartEdit = cart || {};

    if(cartEdit){
      if(cartEdit[item.id]){
        console.log(cartEdit[item.id].cant)
        cartEdit[item.id].cant ++
      }else{
        cartEdit[item.id] = item
      }
    }

    setCart({...cartEdit});
  };

  const removeFromCart = (itemId) => {
    const cartEdit = cart
    delete cartEdit[itemId]
    setCart({...cartEdit});
  };

  const removeOneArticleFromCart = (itemId) => {
    const cartEdit = cart

    if(cartEdit[itemId].cant == 1){
      removeFromCart(itemId)
    }else{
      cartEdit[itemId].cant --
      setCart({...cartEdit})
    }

  };

  const addOneArticleFromCart = (itemId) => {
    const cartEdit = cart
    cartEdit[itemId].cant ++
      setCart({...cartEdit})
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        removeOneArticleFromCart,
        addOneArticleFromCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
